<template>
  <v-card
    flat
    class="cart-info-card rounded-md h-100 px-6 py-3 px-sm-3 py-sm-3"
  >
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6">
        <v-row
          no-gutters
          class="text-body-2 default--text font-weight-semibold my-5"
        >
          <v-col cols="4">
            <span>{{ $t("orders.header.orderNumber") }}</span>
          </v-col>
          <v-col cols="4">
            <span>{{ $t("orders.header.status") }}</span>
          </v-col>
          <v-col cols="4">
            <span>{{ $t("orders.header.invoice") }}</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="text-body-2 my-5">
          <v-col cols="4">
            <span class="primary--text font-weight-600"
              >#{{ order.orderId }}</span
            >
          </v-col>
          <v-col cols="4">
            <OrderStatusBadge v-bind:order="order" class="default--text" />
          </v-col>
          <v-col cols="4">
            <span v-if="billNumber">
              {{ billNumber }}
            </span>
            <span v-else>
              {{ hasBill ? $t("common.yes") : $t("common.no") }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="order-actions d-flex justify-end justify-sm-end align-end"
      >
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.deliveryAddress.addressType != 'home'"
              color="primary"
              icon
              large
              @click.stop.prevent="openQrCodePopup()"
              depressed
              outlined
              class="rounded-circle pa-1 mx-1"
            >
              <v-icon size="23" class="pa-1 ma-1">$qrcode</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.qrCode") }}</span>
        </v-tooltip> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="showDigitalReceipt(order.orderId)"
              depressed
              icon
              large
              outlined
              class="rounded-circle pa-1 mx-1"
              v-if="!isOldOrder && order.orderStatusId == 8"
            >
              <v-icon size="23" class="pa-1 ma-1">$invoice</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.receipt") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="deleteOrder(order.orderId)"
              depressed
              icon
              large
              outlined
              class="rounded-circle pa-1 mx-1"
              v-if="order.isDeletable"
            >
              <v-icon size="23" class="pa-1 ma-1">$trashXmark</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.delete") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.isEditable"
              @click="editOrder(order.orderId)"
              color="primary"
              icon
              large
              depressed
              outlined
              class="rounded-circle pa-1 mx-1"
            >
              <v-icon size="23" class="pa-1 ma-1">$edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.edit") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.isPayable"
              @click="showPayment"
              color="primary"
              icon
              large
              depressed
              outlined
              class="rounded-circle pa-1 mx-1"
            >
              <v-icon size="23" class="pa-1 ma-1">$creditCard</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.retryPayment") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
              color="primary"
              icon
              large
              @click.stop.prevent="addAllToCart(order.orderId)"
              depressed
              :aria-label="$t('orders.button.reorder')"
              class="rounded-circle pa-1 mx-1"
            >
              <v-icon size="23" class="pa-1 ma-1">$cartArrowDown</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.reorder") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="printOrder"
              color="primary"
              depressed
              icon
              large
              :aria-label="$t('orders.button.print')"
              class="rounded-circle pa-1 mx-1"
              ><v-icon size="23" class="pa-1 ma-1">$print</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("orders.button.print") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.order-actions {
  .v-btn {
    border: 1px solid;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-btn {
      margin: 0px 8px;
    }
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import OrderQrCode from "@/components/orders/OrderQrCode.vue";
// import CustomService from "@/service/customService";

import downloadDocumentMixin from "~/mixins/downloadDocument";
import DialogOrderReceipt from "./DialogOrderReceipt.vue";

export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge },
  props: ["order"],
  data() {
    return {};
  },
  computed: {
    receiptUrl() {
      let documentTypeId = 12;
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=${documentTypeId}&type=pdf&order_id=${orderId}`;
    },
    billNumber() {
      if (this.order && this.order.orderInfos) {
        return this.order.orderInfos.INVOICE_NUMBER;
      }
      return undefined;
    },
    hasBill() {
      if (
        this.order &&
        this.order.orderInfos &&
        this.order.orderInfos.need_invoice == "true"
      ) {
        return true;
      }
      return false;
    },
    isOldOrder() {
      return !(
        this.order.shippingAddress &&
        this.order.shippingAddress.deliveryServiceId
      );
    }
  },
  methods: {
    async showDigitalReceipt(orderId) {
      this.$dialog.show(DialogOrderReceipt, {
        fullscreen: this.$vuetify.breakpoint.xsOnly,
        orderId: orderId
      });
    },
    downloadDocument(orderId) {
      var url = window.event.currentTarget.href;
      this.handleReceiptDownload(url, orderId);
    },
    openQrCodePopup() {
      global.vm.$dialog.show(OrderQrCode, {
        waitForResult: true,
        fullscreen: global.vm.$vuetify.breakpoint.xsOnly,
        qrFullscreen: global.vm.$vuetify.breakpoint.xsOnly,
        order: this.order
      });
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
