<template>
  <div class="order-detail d-flex flex-column px-0" v-if="order">
    <CategoryTitle :category="category" :add-container="false" />
    <v-row justify="space-between" class="mb-3">
      <v-col cols="12">
        <OrderDetailInfo
          :order="order"
          @addAllToCart="addAllToCart"
          @deleteOrder="deleteOrder"
          @editOrder="editOrder"
          @showPayment="showPayment = !showPayment"
        />
      </v-col>

      <v-col cols="12" v-if="showPayment" class="mb-5">
        <span class="d-block text-h2 line-height-1 mt-3">{{
          $t("order.retryPayment")
        }}</span>
        <PaymentTypeList
          mode="order"
          :order-id="order.orderId"
          :deliveryServiceId="order.shippingAddress.deliveryServiceId"
          class="ptl-wrapper py-4"
          :options="{
            showRememberCard: true,
            rememberCardDefaultValue: true,
            showPaymentMethodImage: false,
            showCardLogos: true,
            showPrebilledAmountInfoText: true,
            hidePrevButton: true,
            mainBackgroundColor: 'transparent',
            confirm: {
              show: true,
              class: 'rounded-pill white--text'
            },
            back: {
              outline: true,
              class: 'primary--text rounded-pill payment-back-btn'
            },
            titleStyle: 'default--text payment-title mb-2 text-uppercase'
          }"
          :paymentNameToPaymentInfo="true"
          :showAddNewCardWarningInfo="false"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-col cols="12" class="px-0 pt-0">
          <CartInfoAddressCard
            :shippingAddress="order.shippingAddress"
            class="w-100 cart-info-timeslot-card"
          />
        </v-col>
        <v-col cols="12" class="px-0">
          <CartInfoTimeslotCard
            v-if="order"
            :shippingAddress="order.shippingAddress"
            :timeslot="order.timeslot"
            class="w-100 cart-info-timeslot-card"
            :value="value"
          /> </v-col
      ></v-col>
      <v-col cols="12" sm="6">
        <v-card
          v-if="isOrderDelivered || isOrderPrepared"
          flat
          outline
          class="checkout-summary order-summary-prepared pa-3 pb-2 mb-3 rounded-md"
        >
          <div class="mb-3 text-body-1 font-weight-semibold text-uppercase">
            {{ $t("checkout.summary.cart") }}
          </div>

          <v-card-text class="summary-text pa-0">
            <template v-if="isOrderPrepared">
              <div
                class="d-flex justify-space-between align-center font-weight-semibold mb-1"
              >
                <span>{{ $t("checkout.summary.totalProducts") }}</span>
                <span>{{ $n(order.totalPrice, "currency") }}</span>
              </div>

              <div
                class="d-flex justify-space-between align-center text-body-2  mb-1"
                v-if="order.deliveryFee > 0"
              >
                <span>{{ deliveryFeeTitle }}</span>
                <span>{{ $n(order.deliveryFee, "currency") }}</span>
              </div>

              <div
                class="d-flex justify-space-between align-center text-body-2 mb-1 font-weight-semibold mt-2"
                v-if="order.giftTotal > 0"
              >
                <span>{{ $t("checkout.summary.giftTotal") }}</span>
                <span>{{ $n(order.giftTotal, "currency") }}</span>
              </div>
              <div
                class="d-flex justify-space-between align-center text-body-2 font-weight-semibold mb-1 mt-4"
                v-if="order.packageTotal > 0"
              >
                <span>{{ $t("checkout.summary.packageTotal") }}</span>
                <span>{{ $n(order.packageTotal, "currency") }}</span>
              </div>
            </template>

            <div class="d-flex justify-space-between align-center mt-4">
              <span class="text-h4">
                {{
                  isOrderPrepared
                    ? $t("checkout.summary.totalPrepared")
                    : $t("checkout.summary.totalDelivered")
                }}
              </span>
              <span class="text-h3">
                <OrderGrossTotal
                  :item="order"
                  :delivered="isOrderDelivered"
                ></OrderGrossTotal>
              </span>
            </div>
          </v-card-text>
        </v-card>
        <CheckoutSummary
          v-else
          :orderCart="order"
          :delivered="delivered"
          :canDeleteGift="false"
        />
      </v-col>
    </v-row>

    <OrderDetailTable
      v-if="!loading"
      :suborders="itemList"
      :order="order"
      class="mt-4 mt-sm-12"
      :delivered="delivered"
      @reload="reload"
    />
    <v-row
      v-if="!refundItems"
      no-gutters
      class="backbtn-container mt-5 justify-center justify-sm-start"
    >
      <router-link
        class="d-flex align-center justify-sm-start mr-md-auto order-detail__actions--cta--back text-decoration-none"
        :to="{ name: 'Orders' }"
      >
        <v-icon class="mr-3" color="primary" size="20">$arrowLeft</v-icon>
        <span color="default" class="default--text font-weight-semibold">{{
          $t("orders.button.backToOrders")
        }}</span>
      </router-link>
      <!-- <v-btn
        v-if="showSaveBtn"
        color="primary"
        class="ml-3 mt-3 mt-sm-0"
        @click="saveSubstitute"
      >
        <span v-html="$t('orders.button.saveSubstitutes')"></span>
      </v-btn> -->
    </v-row>
    <v-row no-gutters v-else class="backbtn-container mt-5 justify-end">
      <v-btn
        color="primary"
        depressed
        v-bind:to="{
          name: 'RefundRequest',
          query: {
            orderId: order.orderId,
            itemId: refundItems
          }
        }"
      >
        <span v-html="$t('refund.sentRequests')"></span>
      </v-btn>
    </v-row>
    <!-- <div class="mt-6">
      <h4>{{ $t("refund.refundForOrder") }}</h4>
      <RefundTable :orderId="order.orderId" class="generic-list" />
    </div> -->
  </div>
</template>
<style scoped lang="scss">
.ptl-wrapper {
  :deep(.primary--text) {
    color: #464646 !important;
  }
  :deep(.payment-title) {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  :deep(.prebilled-amount) {
    font-weight: normal;
    line-height: 1.2;
    font-size: 12px !important;
    .v-alert__content div {
      font-size: 12px !important;
    }
  }
  :deep(.payment-type) {
    .payment-back-btn {
      border: 1px solid var(--v-primary-base);
      color: var(--v-primary-base) !important;
    }
    .v-btn {
      border-radius: 9999px !important;
    }
  }
  :deep(.add-new-card-btn) {
    box-shadow: none;
    margin-right: 12px;
    height: 30px !important;
    min-width: 190px !important;
    padding: 0 16px;
  }
  :deep(.prebilled-amount) {
    margin-bottom: 0px !important;
  }
  :deep(.actions-btn-container) {
    display: flex !important;
    // justify-content: space-between !important;
    padding: 0 8px !important;
  }
  :deep(.go-back-btn) {
    height: 44px !important;
    min-width: 78px !important;
    padding: 0 19.5555555556px !important;
    color: var(--v-primary-base) !important;
    border: 1px solid var(--v-primary-base) !important;
  }
  :deep(.gateway-btn) {
    height: 44px !important;
    min-width: 78px !important;
    padding: 0 19.5555555556px !important;
    color: #ffffff !important;
  }

  :deep(.add-card-container) {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  :deep(.remember-card-checkbox) {
    margin-bottom: 12px;
    .v-label {
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .v-icon {
      font-size: 20px !important;
    }
  }
}
.order-detail {
  .cart-info-card {
    min-height: 75px;
    align-items: center;
    display: flex;
  }
  .card {
    padding: 10px 20px !important;
    box-sizing: border-box;
    background-color: var(--v-grey-lighten3) !important;
    border: 1px solid var(--v-grey-lighten3) !important;
    border-radius: 8px;
    &:hover {
      background-color: var(--v-primary-lighten2);
    }
    &.cart-info {
      padding: 35px 0 27px 12px !important;
      .text,
      .value {
        line-height: 24px !important;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        padding: 20px !important;
      }
    }
  }
  .payment-methods {
    margin-top: 0px !important;
  }
  .cart-info-timeslot-card,
  .cart-info-address-card {
    pointer-events: none;
  }
  .remove-gift {
    display: none;
  }
}

@media print {
  .order-detail {
    .cart-info-cards {
      flex-direction: row !important;
      gap: 12px !important;
    }

    .cart-info-cards div:first-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import OrderDetailTable from "@/components/orders/OrderDetailTable.vue";
import OrderGrossTotal from "@/components/orders/OrderGrossTotal";
import OrderDetailInfo from "@/components/orders/OrderDetailInfo.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapState, mapActions, mapGetters } from "vuex";
import CustomService from "@/service/customService";
import RefundService from "@/service/refundService";
import toNumber from "lodash/toNumber";

export default {
  name: "Order",
  data() {
    return {
      loading: true,
      order: null,
      itemList: [],
      valid: true,
      showPayment: false,
      requiredRules: [v => !!v || "Campo obbligatorio"],
      paymentTypeListOptions: {
        flatPanels: true,
        showPaymentMethodImage: true,
        showCardLogos: false,
        mainBackgroundColor: "transparent",
        titleStyle: "default--text text-uppercase payment-type-list-title",
        back: {
          label: "Torna Indietro",
          class: "paymenbt-back-button",
          show: true
        }
      },
      menuNewDate: false,
      refundItems: null
    };
  },
  mixins: [categoryMixins, reload],
  components: {
    OrderDetailInfo,
    OrderDetailTable,
    CartInfoAddressCard,
    OrderGrossTotal,
    CartInfoTimeslotCard,
    CheckoutSummary,
    CategoryTitle,
    PaymentTypeList
    // RefundTable
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      isSsoUser: "cart/isSsoUser"
    }),
    deliveryFeeTitle() {
      return this.$t(
        "checkout.summary.deliveryFeeTitle." +
          this.order.shippingAddress.addressType || "home"
      );
    },
    isOrderPrepared() {
      // quando ordine in stato 18 abbiamo totalPrice e grossTotal valorizzati correttamente con gli articoli pickerati (anche in stato 6, ma solo per i drive)
      return (
        this.order.orderStatusId == 18 ||
        (this.order.orderStatusId == 6 &&
          this.order.shippingAddress.addressType === "drive")
      );
    },
    isOrderDelivered() {
      // quando ordine in stato 8 abbiamo il totale esatto da conpay
      return this.order.orderStatusId == 8;
    },
    enableSave() {
      return !this.valid;
    },
    serviceImg() {
      return this.$t(
        "navbar.service." +
          this.order.shippingAddress.deliveryServiceId +
          ".icon",
        {
          color: "color"
        }
      );
    },
    value() {
      if (this.order.timeslot) {
        let date = this.$dayjs(this.order.timeslot.date);
        return (
          "Da " +
          date.format("ddd D MMMM") +
          " nella fascia oraria " +
          this.$dayjs(this.order.timeslot.beginTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm") +
          " - " +
          this.$dayjs(this.order.timeslot.endTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm")
        );
      }
      return this.$t("navbar.noTimeslot");
    },
    delivered() {
      return (
        this.order.orderStatusId == 6 ||
        this.order.orderStatusId == 8 ||
        this.order.orderStatusId == 18
      );
    }
  },
  methods: {
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart",
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    async addAllToCart({ orderId }) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        let result = await this.addProductsFromOrder(orderId);
        if (result && result.cartItems && result.cartItems.length > 0) {
          this.setCartItemInfo({
            items: result.cartItems,
            name: "accept_alternatives",
            value: global.config.acceptAlternatives
          });
        }
      }
    },
    async deleteOrder({ orderId }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });

      if (res) {
        await OrderService.deleteOrder(orderId);
        AnalyticsService.refund(this.order);
        await this.reload(orderId);
      }
    },
    async editOrder({ orderId }) {
      try {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          await OrderService.editOrder(orderId);
          this.loadCart();
          this.$router.push({
            name: "Checkout"
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    async reload(orderId) {
      this.loading = true;
      this.order = await OrderService.getOrder(orderId, true);
      this.order.orderId = toNumber(this.order.orderId);
      this.handleItems();
      if (this.order.isPayable) {
        this.showPayment = true;
      }
      this.loading = false;
    },
    changeDateOrder() {
      CustomService.changeDateOfOrder(
        this.order.orderId,
        this.order.timeslot.date
      );
    },
    handleItems() {
      this.itemList = [];
      for (let s = 0; s < this.order.suborders.length; s++) {
        let suborderItems = this.order.suborders[s].suborderItems;

        let orderedSuborderItems = [];
        try {
          for (let i = 0; i < suborderItems.length; i++) {
            if (!suborderItems[i].orderItemReplacedId) {
              orderedSuborderItems.push(suborderItems[i]);
            } else {
              for (let k = 0; k < orderedSuborderItems.length; k++) {
                if (
                  orderedSuborderItems[k].orderItemId ==
                  suborderItems[i].orderItemReplacedId
                ) {
                  orderedSuborderItems.splice(k + 1, 0, suborderItems[i]);
                  break;
                }
              }
            }
          }
        } catch (err) {
          console.log(err);
          orderedSuborderItems = suborderItems;
        }

        this.itemList = this.itemList.concat(orderedSuborderItems);
      }
      for (let i = 0; i < this.itemList.length; i++) {
        let item = this.itemList[i];

        item.deliveredType = "ordered";
        if (item.deliveredQuantity < item.quantity) {
          item.deliveredType = "partial";
        }
        if (item.deliveredQuantity > 0 || item.deliveredWeight > 0) {
          if (item.orderItemReplacedId) {
            item.deliveredType = "alternative";
          }
        } else {
          // doubtful request from core because there is wrong orderItem with quantity 0 and replaced status, and then the same product but correctly picked
          if (
            i < this.itemList.length - 2 &&
            this.itemList[i + 1].product.productId == item.product.productId
          ) {
            item.wrongPickedProduct = true;
          }
          item.deliveredType = "replaced";
        }
      }
    },
    async saveSubstitute() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.confirmOrderEdit")
      });
      if (res) {
        RefundService.saveSubstitute(this.order.orderId).then(() => {
          this.reload(this.order.orderId);
        });
      }
    }
  },
  created() {
    this.reload(this.$route.params.orderId);
  }
};
</script>
